<template>
  <v-app id="main-app">
    <v-container class="fill-height" fluid>
      <v-card class="mx-auto px-10 pb-9" width="500px">
        <v-card-title class="justify-center pt-12"><img
            :src="logoLogin"
        />
          <h3 style="margin-left: 10px; font-weight: 400;">{{$t('product-name')}}</h3></v-card-title>
        <!-- <v-card-subtitle class="text-center py-1 title-login" style="font-size: 20px !important;font-weight: bold;">{{$t('successfully-activation')}}</v-card-subtitle> -->
		<template>
  <v-card-subtitle class="text-center py-1 title-login" style="font-size: 20px !important;font-weight: bold;">
    {{ $t('successfully-activation') }}
  </v-card-subtitle>
</template>

        <v-card-subtitle class="text-center title-login primary--text pt-0" style="font-size: 16px !important">{{$t('successfully-activation-text')}}</v-card-subtitle>
        <alert-list-auth />
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="6">
          <a
              target="_blank"
              href=""
          >
            <img
                alt="Download on the App Store"
                :src="iosQrCode"
                width="300"
                class="mx-n15 my-n15"
            />
            <img
                    alt="Download on the App Store"
                    v-if="lang === 'en'"
                    :src="englishAppStore"
                    style="
                    margin-right: 10px;
                    box-sizing: border-box;
                    max-width: 100%;
                    border: none;
                    width: 165px;
                    height: 50px;
                  "
            />
            <img
                alt="Download on the App Store"
                v-if="lang === 'es'"
                :src="spanishAppStore"
                style="
                    margin-right: 30px;
                    box-sizing: border-box;
                    max-width: 100%;
                    border: none;
                    width: 165px;
                    height: 50px;
                  "
            />
<!--            <v-img :src="" class="mr-7"/>-->
          </a>
            </v-col>
            <v-col cols="6">
              <a
                  target="_blank"
                  href=""
              >
                <img
                    alt="Download on the App Store"
                    :src="androidQrCode"
                    width="300"
                    class="mx-n11 my-n15"

                />
                <img
                    alt="Get it on Google Play"
                    v-if="lang === 'en'"
                    :src="englishPlayStore"
                    style="
                    margin-left: 25px;
                    box-sizing: border-box;
                    max-width: 100%;
                    border: none;
                    width: 165px;
                    height: 50px;
                  "
                />
                <img
                    alt="Get it on Google Play"
                    v-if="lang === 'es'"
                    :src="spanishPlayStore"
                    style="
                    margin-left: 25px;
                    box-sizing: border-box;
                    max-width: 100%;
                    border: none;
                    width: 165px;
                    height: 50px;
                  "
                />
<!--                <v-img :src="" class="ml-7"/>-->
              </a>
            </v-col>
          </v-row>
        </v-card-text>
        <v-footer color="transparent" class="px-10">
          <v-row dense>
            <v-col class="text-center black--text ma-0 pa-0" cols="12">
              <span style="font-size: 11px; color: grey">{{$t('signing-in-to-this-webapp')}}</span>
              <span style="font-size: 11px; cursor: pointer;" class="primary--text" @click="redirectToWebsiteTerms"> {{$t('website-terms-of-use')}} </span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col class="text-center black--text mt-0 pt-0" cols="12">
              <span style="font-size: 11px; color: grey"> {{$t('acknowledge')}}</span>
              <span class="primary--text" style="font-size: 11px; color: grey; cursor: pointer;" @click="redirectToPrivacyTerms"> {{$t('privacy-policy')}}</span>
            </v-col>
          </v-row>
        </v-footer>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import AlertListAuth from '../Client/components/AlertListAuth.vue';
import logoBridge from '../../../public/logo_bridge.png';
// import CountryFlag from 'vue-country-flag';
import { logoLogin, englishPlayStore, englishAppStore, spanishPlayStore, spanishAppStore, iosQrCode, androidQrCode } from '@/assets';

export default {
	components: {
		AlertListAuth,
		// CountryFlag,
	},
	data () {
		return {
      logoLogin: logoLogin,
			englishPlayStore,
      iosQrCode,
      androidQrCode,
			englishAppStore,
			spanishPlayStore,
			spanishAppStore,
			showForm: 1,
			showPassword: false,
			logo_bridge: logoBridge,
			lang: 'en',
			email: '',
			password: '',
			emailSent: false,
		};
	},
	mounted () {
		this.language(this.$route.query.language);
		if (this.$store.getters['authentication/hasStoredSession']) {
			this.$store.dispatch('authentication/doLogout');
			this.$store.commit('alerts/clear');
			localStorage.clear();
		}
	},
	methods: {
		language (val) {
			if (val === null || val === undefined) {
				this.lang = 'en';
				this.$i18n.locale = 'en';
			} else {
				if (val === 'ENG') {
					this.lang = 'en';
					this.$i18n.locale = 'en';
				} else if (val === 'ESP') {
					this.lang = 'es';
					this.$i18n.locale = 'es';
				} else {
					this.lang = 'en';
					this.$i18n.locale = 'en';
				}
			}
		},
		redirectToWebsiteTerms () {
			window.open('https://www.wellnessmetric.net/wellnessmetric_website_terms_of_use.html', '_blank');
		},
		redirectToPrivacyTerms () {
			window.open('https://www.wellnessmetric.net/wellnessmetric_website_privacy_policy.html', '_blank');
		},
	},
};
</script>
<style scoped>
#main-app {
  background: #F9F8FB;
}
</style>
